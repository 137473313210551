@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";
.wms-dropdown-component {
    width: 50%;
    float: left;
    position: relative;
    padding: 0px 0px;
    margin-bottom: 14px;
    label {
        @include label;
        padding: 0 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    select {
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid $border-color;
        width: 100%;
        height: 32px;
        color: $input-color;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        padding: 0px 30px 0px 10px;
        line-height: 30px;
    }

    select:focus {
        border-color: rgba(0, 0, 0, 0.15);
        //color: #656565;
        //background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    }

    .caret{
        display: block;
        position: absolute;
        margin: auto;
        z-index: 10;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-top: 5px solid #2c405a;
        pointer-events: none;
        top: 14px;
        right: 10px;
    }

    .form_group {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;
        max-height: 100px;
        float: left;
        padding: 0 6px;
        position: relative;

        &.no_margin {
            margin: 0px;
        }
        .caret{
            right: 18px;
        }
    }

    .single-select {
        select:empty {
            border: 1px solid blue;
        }

    }
    .select_box {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;
        max-height: 100px;
        float: left;
        padding: 0px;
        position: relative;

        select {
            border-radius: 4px 0 0 4px;
            border-right: 1px solid white !important;
        }
    }

.wms_required_input {
    border: 1px solid $requireinput-border !important;

    &:focus {
        border-color: $primary-themecolor !important;
    }
}

.k_state_disabled {
    outline: none;
    cursor: default;
    opacity: .6;
    filter: grayscale(0.1);
    pointer-events: none;
}
    &.kendo-dropdown-wrapper{
        .k-dropdown {
            width: 100%;
            height: 32px;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
            position: relative;
            z-index: 10;
            border-radius: 4px;
            background-color: transparent;
            border: 1px solid #bbbbbb;
            .k-dropdown-wrap {
                border: none;
                cursor: pointer;
                color: #7d7d7d;
                background: transparent;
                &.k-state-focused {
                    //  box-shadow: unset;
                    border-color: rgba(0, 0, 0, 0.15);
                    // color: #656565;
                    // background-color: #ffffff;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
                }
                .k-select{
                    display: none;
                }
                .k-input {
                    line-height: 32px;
                    padding: 0 10px;
                    height: 32px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                    color: #222222;
                }
            }
        }
    }

    .div_disable {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: not-allowed;
        height: 40px;
        display: inline-block;
    }
    .div_tooltip {
        position: absolute;
        top: 40px;
        left: 0;
        min-width: 180px;
        z-index: 12;
        font-size: 14px;
        padding: 4px 8px;
        background: black !important;
        color: white !important;
        border: 1px solid black !important;
        &:before {
            content: "\A";
            position: absolute;
            top: 1px;
            left: 30px;
            border-style: solid;
            border-width: 11px 11px 0px 20px;
            -webkit-transform: rotate(-135deg);
            border-color: transparent black transparent transparent;
        }
    }

    .div_tooltip:before {
        content: "\A";
        border-style: solid;
        border-width: 11px 11px 0px 20px;
        position: absolute;
        -webkit-transform: rotate(-135deg);
        left: 75px;
        top: 1px;
    }
.wms-dropdown-component select:disabled {
    border-color: rgba(0, 0, 0, 0.06);
    background: rgba(0, 0, 0, 0.03);
    opacity: 1;
}
}