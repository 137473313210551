@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";

.wms_context_sub_menu_item_wrapper {
    li {
        .k-link {
            .wms-badge {
                margin-left: 10px;
            }
        }
    }
    .k_state_disabled {
        outline: none;
        opacity: 0.6;
        filter: grayscale(0.1);
        cursor: not-allowed !important;
        pointer-events: none !important;
    }
    .mydisabled{
        pointer-events: all !important;
        span{
            cursor: not-allowed !important;
            .wms-badge {
                opacity: 0.5;
            }
        }
    }
}