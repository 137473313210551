@import "./variables";
@import "./kendo_variables";
@import "./mixing";

.k-animation-container {
  .k-column-menu {
    ul {
      li {
        background: #fff !important;

        .k-link {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: #222 !important;
          background: white !important;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        .k-filterable {
          background: white;
          width: 210px;
          max-width: 100%;

          .k-filter-menu {
            .k-filter-help-text {
              padding: 10px;
              border: none;
              font-size: 14px;
              font-weight: 500;
            }

            .k-widget {
              padding: 0 10px;
              box-shadow: none;
              outline: none;

              span {
                &.k-dropdown-wrap {
                  background: white;
                  color: #6d6d6d !important;
                  font-weight: 500 !important;
                  font-size: 14px !important;
                }
              }
            }

            .k-textbox {
              width: calc(100% - 36px);
              margin: 10px auto;
            }

            button {
              width: 43%;
              margin-left: 9px;
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .k-list {
    .k-item {
      &.k-state-hover {
        background: $secondary !important;
        color: white !important;
      }

      &.k-state-selected {
        background: $secondary !important;
        color: white !important;
      }

      &.k-state-focused {
        background: $secondary !important;
        color: white !important;
      }
    }
  }
}

.k-grid {
  thead {
      tr {
          background:  #e2effc !important;
          border-color: #cee1f2 !important;
      }
  }
  tr {
      background: #ffffff !important;
      &:hover{
          background: transparent !important;
      }
      td {
          color: #3f536e !important;
          border-color:  rgba(255, 255, 255, 0.15) !important;
      }
  }
  tr.k-alt {
      background-color: #F5F5F5 !important;
  }
  tr.k-state-selected,
  td.k-state-selected {
      background-color: #8eb8dd !important;
  }
  tr.k-grouping-row {
      td {
          background:  #ffffff !important;
      }
  }
  tbody {
      .k-group-cell {
          background:  #ffffff !important;
      }
  }
}

/******  Grid-Table Design   ***********/
.k-grid {
  border-top-color:  #b7d2e5 !important;
  .k-grid-header {
      background:  #e2effc !important;
      border-color:  #d8e5ee !important;
      .k-header {
          color: #527999 !important;
          .k-header-column-menu {
              border-color: #b7d2e5 !important;
          }
          .k-header-column-menu.k-state-active {
              color: #656565 !important;
              border-color:   #e2effc !important;
          }
      }
      .k-header>.k-link {
          .k-icon {
              color: #527999 !important;
          }
      }
  }
  .k-grid-content {
      // background: var(--body-bg);
      tr td {
          .k-grid-content {
              // background: var(--body-bg);
          }
      }
  }
}

.wms-over-allocated {
  background-color:  #f1e0dc !important;
}

.wms-over-allocated.k-alt {
  background-color:  #f1e0dc !important;
}
/******  TabListing CSS   ***********/
.tab-listing {
  ul {
      border: 1px solid $blue_color;
      border-radius: 30px;
      overflow: hidden;
      display: inline-flex;
      margin-top: 4px;
      margin: 0px;
      padding: 0px;

      li {
          border-right: 1px solid $blue_color;
          display: inline-block;

          &:last-child {
              border-right: none;
          }

          label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $blue_color;
              background-color: transparent;
              font-size: 14px;
              padding: 16px 20px;
              line-height: 0;
              font-weight: 600;
              cursor: pointer;
              display: block;
              transition: 0.2s ease-in-out;
              -webkit-transition: 0.2s ease-in-out;
              -moz-transition: 0.2s ease-in-out;

              &:hover,
              &:active,
              &:focus {
                  background: $blue_color;
                  color: white;
              }

              @include desktop_1024 {
                  font-size: 12px;
                  padding: 15px 15px;
              }
          }

          &.active-tab {
              background: $blue_color;

              label {
                  color: white;
              }
          }
      }
  }
}