@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";


.wms-model-new-ui-wrapper{
    border-radius: 4px;
    //top:100px !important;
    background: #fff;
    height: auto !important;
    &.model-height-fixed{
        height: 100% !important;
    }
    &.k-window-maximized {
        top: 20px !important;
        bottom: 20px !important;
        left: 20px !important;
        right: 20px !important;
        width: auto !important;
        max-height: 100% !important;

        .k-window-content {
            max-height: 100% !important;
        }
    }
    .k-window-titlebar{
        --seprator-color: #bad2ea;
        --sidepanel-bg: #bad2ea;
        background: #E2EFFC !important;
        padding: 0;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #bad2ea !important;
        height: 80px;
        min-height: 80px;
        .k-window-title{
            color: #222222 !important;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
            padding: 2px 0px 0px 30px;
        }
        .k-window-actions{
            padding: 15px;
            .k-button {
                color: #222222;
            }
        }
    }
    .Modal1 {
        // height: unset !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        border-radius: 0 0 4px 4px;
        background: #fff;
        .model-content-wrapper {
            padding: 30px;
            overflow-y: auto;
            overflow: overlay;
            flex: 1;
            background: #fff;
            .wms-container-wrapper {
                &:last-child{
                    .wms-area-content {
                        margin-bottom:0 !important;
                        .wms-area-content-darker{
                            margin-bottom:0 !important;
                        }
                    }
                }
            }
            .wms-collapsible-div {
                &:last-child {
                    margin-bottom:0 !important;
                }
            }
        }
        .wms-area-content-darker {
            // overflow-y: auto;
            // flex: 1;
        }
        .footer-btn-holder{
            display: flex;
            height: 95px;
            min-height: 95px;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 0 0 4px 4px;
            .wms-button-component {
                margin: 0 12px 6px 0;
                .button_holder {
                    .wms-button-tooltip {
                        top: unset;
                        bottom: 44px;
                        &:before {
                            -webkit-transform: rotate(-315deg);
                            top: unset;
                            bottom: 0px;
                        }
                    }
                }
            }
        }
    }
}
.model-tooltip-title-p {
    max-width: 300px;
    max-height: 300px;
}