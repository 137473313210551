@import "../assets/scss/common";
@import "../assets/scss/variables";
@import "../assets/scss/mixing";


.feature-section-wrapper {
  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fafbfc;
  .wms-dropdown-component  {
    margin: 10px 16px;
    width: 300px;
  }
  .view-record {
    color: #005CA8;
    cursor: pointer;
    margin-right: 10px;
  }
  .charge-delete {
    background: transparent;
    border: none;
    cursor: pointer;
    color: red;
  }
  .display-inline {
    display: flex;
    .wms-button-component  {
      margin-top: 32px;
      &.test-auth-token {
        margin-left: 15px;
      }
    }
  }
  .batch-option-wrapper {
    position: absolute;
    float: left;
    width: auto;
  }
  .wms-virtual-grid-holder {
    margin-top: 35px;
    .refresh-btn-wrapper {
      padding-left: 10px;
      button {
        padding: 8px 10px 8px 10px;
      }
    }
    .build-deploy {
        button {
          padding: 7px 15px 9px 15px;
        }
    }
  }
}
.wms-model-new-ui-wrapper {
  .wms-collapsible-div{
    .wms-collapsible-content {

      padding: 0; 
      .detail-section {
        padding: 20px;
        color: #222222;
        border-bottom: 1px solid #eeeeee;
        label {
          padding-right: 10px;
          font-weight: bold;
        }
      }
    }
  } 
}
.build-and-deploy-wrapper {
  .wms-collapsible-content {
    display: flex !important;
    flex-direction: column;
    margin: 20px;
  }
}
