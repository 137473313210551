@import "../../assets/scss/common";
@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";


.wms-main-content {
  flex: 1;
  width: 100%;
  height: 100%;
  color: #000000;
  background: #f5f5f5;
  max-width: calc(100% - 200px);
  flex: 0 0 calc(100% - 200px);
  display: flex;
  flex-direction: column;
  .k-tabstrip-wrapper {
    padding: 10px 0;
  }

  header {
    min-height: 50px;
    width: 100%;
    background-color: white;

    &.wms-header-navigation-menu {
      list-style-type: none;
      margin: 0 0 0 0;
      padding: 0;
      display: flex;
      align-items: center;

      li {
        background-color: transparent;
        position: relative;
        overflow: visible;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        padding: 15px 0 14px 0;
        margin: 0 20px;
        display: inline-flex;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        @include desktop_1024 {
          font-size: 12px;
          margin: 0 10px;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }

        &.active-tab {
          &::after {
            opacity: 1;
          }
        }

        span {
          color: $text-color;
          font-size: 14px;
          line-height: 12px;
          text-transform: capitalize;
          font-weight: normal;
          padding: 0;
        }

        .wmsi-management-details {
          border: 1px solid;
          border-radius: 30px;
          padding: 2px 6px;
          font-size: 10px;
          font-weight: 500;
          margin: auto 0px auto 8px;
          display: flex;

          &.wmsi-yellow {
            color: #faca00;
            border-color: #faca00;
          }

          &.wmsi-green {
            color: #ffffff;
            border-color: #81c926;
            background: #81c926;
          }

          &.wmsi-red {
            color: #ffffff;
            border-color: #ea3b24;
            background: #ea3b24;
          }
        }
      }
      .wms-user-settings {
        display: flex;
        top: 8px;
        position: fixed;
        z-index: 100;
        right: 15px;

        .wmsi-user-login {
          ul {
            li {
              .user-name {
                background: none;
                color: inherit;
                border: none;
                font: inherit;
              }
              .k-animation-container {
                left: 0 !important;
                top: 40px !important;
              }
              &.k-item {
                font-size: 16px;
                font-weight: 400;
                color: $black;
                display: inline-block;

                @include desktop_1024 {
                  font-size: 14px;
                }

                &:hover,
                &:focus {
                  box-shadow: none;
                  outline: none;
                  color: $black;
                }
              }
            }
          }
        }

        .cogs-setting {
          margin: auto;

          span {
            cursor: pointer;
            color: $black;
          }
        }
      }
    }
  }
  .wms-container {
    height: 100%;
    // width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

@keyframes hideFilterSection {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -400px;
  }
  100% {
    margin-left: -365px;
  }
}

@keyframes showFilterSection {
  0% {
    margin-left: -365px;
  }
  50% {
    margin-left: -400px;
  }
  100% {
    margin-left: 0;
  }
}
