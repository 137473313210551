@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";
@import "../../assets/scss/common";
.wms-virtual-grid-holder {
    height: 100%;
    margin: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    @include desktop_1280 {
        margin: 0 10px;
    }
    .wms-message-wrapper {
        min-height: 30px;
        padding: 3px 0;
        .error-txt {
            color: $redbtn;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: 600;
            width: 100%;
            display: inline-block;
            text-align: center;
            .wms-click-here{
                cursor: pointer;
                color: #7a7a7a;
                transition: all 0.3s ease-in-out;
                font-weight: 800;
                text-decoration: underline;
                &:hover{
                    color: #515151;
                }
            }
        }
        .loading-text {
            font-size: 14px;
            width: 100%;
            display: inline-flex !important;
            justify-content: center;
            align-items: center;
            color: $form-title;
            img {
                width: 24px;
            }
        }
        .loading-show {
            font-size: 14px;
            color: $form-title;
            width: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.5px;
            img {
                width: 29px;
            }
        }
    }
    .wms_area_content {
        .title {
            width: 100%;
            margin: 8px 0;
            display: inline-block;
            line-height: 23px;
            .sort {
                @extend label;
                float: right;
                color: $form-title;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 28px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .wms_toolbar {
            display: flex;
            width: 100%;
            .button_list {
                display: inline-block;
                margin-top: 9px;
                padding-bottom: 12px;
                margin-right: 45px;
                .checkbox {
                    height: 30px;
                    padding-left: 0px;
                    position: relative;
                    .wms_checkbox_wrapper {
                        padding: 0;
                        margin: -16px;
                        display: inline-block;
                        &.wms_checkbox_disabled{
                            opacity: 0.5;
                            cursor: not-allowed;
                            pointer-events: none;
                        }
                        label {
                            cursor: pointer;
                            color: $input-color;
                            font-weight: 700;
                            input[type=checkbox]:not(:checked) {
                                border: 1px solid #A9AAAB;
                            }
                            input[type=checkbox]:checked {
                                border: 1px solid #92C547;
                            }
                            .wms-option-input {
                                background: white;
								border: 1px solid #d8e5ee;
								border-radius: 4px;
								-webkit-appearance: none;
								-moz-appearance: none;
								-ms-appearance: none;
								-o-appearance: none;
								appearance: none;
								height: 20px;
								width: 20px;
								transition: all 0.15s ease-out 0s;
								cursor: pointer;
								display: inline-block;
								margin-right: 0.5rem;
								outline: none;
								position: relative;
								z-index: 1;
                                vertical-align: middle;
                                margin: 16px 8px 16px 16px;
								&:checked::before {
									height: 10px;
									width: 10px;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									margin: auto;
									position: absolute;
									content: "";
									display: inline-block;
									background-color: #81c926;
									border-radius: 2px;
								}

								&:checked::after {
									animation: select-click-wave 0.65s;
									content: "";
									display: block;
									position: relative;
									z-index: 100;
									background: #81c926;
									border-radius: 4px;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
                            }
                            &.wms-disable-label {
                                opacity: 0.6;
                                cursor: not-allowed;
                                pointer-events: none;
                            }
                        }
                        .div_disable {
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            cursor: not-allowed;
                        }
                        .div_tooltip {
                            position: absolute;
                            z-index: 12;
                            top: 40px;
                            width: 215px;
                            background: black !important;
                            font-size: 14px;
                            color: white !important;
                            border: 1px solid black !important;
                            padding: 10px;
                            &:before {
                                content: "\A";
                                border-style: solid;
                                border-width: 11px 11px 0px 20px;
                                border-color: transparent black transparent transparent;
                                position: absolute;
                                left: 75px;
                                top: 1px;
                                transform: rotate(-135deg);
                                -webkit-transform: rotate(-135deg);
                            }
                        }
                    }
                }
            }
            .scan-key-wrapper {
                display:flex;
                .wms-input-component {
                    width: 325px;
                    .inputlabel {
                        display: none;
                    }
                    .wms-search-input-icon {
                        top: 0;
                    }
                }
                .scan-key-not-found {
                    margin: 5px;
                    color: red;
                }
            }
            .wms-toolbar-button {
                float: right;
                display: flex;
                flex-wrap: wrap;
                margin-left: auto;
                .wms-button-component {
                    display: inline-flex;
                    margin: 0 12px 6px 0;
                }
            }
        }
        .reset_btn {
            display: inline-flex;
            .k-menu {
                float: right;
                margin-bottom: 7px;
                li {
                    overflow: hidden;
                    &.GridMenuTopLink {
                        background: $resetbtn-bg;
                        cursor: pointer;
                        border-radius: 50px;
                        padding: 0 12px 1px 15px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:hover {
                            background: $resetbtn-bg-hover;
                        }
                    }
                    .k-link {
                        padding: 0px;
                        color: $primary-white;
                        // line-height: 25px;
                        display: unset;
                        i {
                            font-size: 14px;
                            // margin-right: 3px;
                        }
                        .options-title{
                            font-weight: 700;
                            white-space: nowrap;
                            display: inline-block;
                            .virtual-grid-options{
                                margin-left: 4px;
                            }
                        }
                        .k-menu-expand-arrow{
                            margin-left: 1px;
                            // margin-bottom: 3px;
                        }
                    }
                }
                li.k-state-border-down {
                    overflow: visible;
                }
            }
            .k-animation-container {
                display: block !important;
                left: inherit !important;
                .k-menu-group {
                    .k-item {
                        &.k-state-default {
                            background: transparent;
                        }
                        &.k-state-hover {
                            background-color: $greenbtn !important;
                            color: white !important;
                        }
                        &.wms-disabled-look {
                            .k-link {
                                color: $input-color !important;
                                &:hover {
                                    color: $input-color !important;
                                }
                            }
                        }
                        .k-link {
                            color: $primary-textcolor !important;
                            padding: 0 10px;
                            font-size: 13px;
                            line-height: 30px;
                            background: transparent;
                            &:hover {
                                background: $greenbtn !important;
                                color: $primary-white !important;
                            }
                        }
                    }
                }
            }
        }
        .wms-grid-details {
            padding: 2px 13px 6px 11px;
            margin: 12px 0 0 0;
            border: 1px solid #00000014;
            background: #e2effc;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .wms_grid_results {
                font-size: 11px;
                text-transform: uppercase;
                font-weight: normal;
                padding-right: 15px;
                letter-spacing: 0.1px;
                color: $input-color;
                span {
                    color: $title_color;
                    padding-right: 7px;
                    font-weight: bold;
                    color: $input-color;
                }
            }
            .wms_grid_selected {
                font-size: 11px;
                text-transform: uppercase;
                font-weight: normal;
                border-left: 1px solid $title_color;
                padding: 0 15px 0 15px;
                color: $input-color;
                span {
                    color: $input-color;
                    padding-right: 7px;
                    font-weight: bold;
                }
            }
            .sort {
                font-size: 11px;
                border-left: 1px solid $title_color;
                padding: 0 15px 0 15px;
                font-weight: bold;
                color: $title_color;
                text-transform: uppercase;
                span {
                    text-transform: none;
                    font-weight: normal;
                    color: $label-color;
                    text-transform: uppercase;
                }
            }
            .wms-filter-text {
                font-size: 14px;
                border-left: 1px solid $title_color;
                padding: 0 15px 0 15px;
                font-weight: bold;
                color: $title_color;
                display: inline-flex;
                span {
                    padding-right: 5px;
                }
                .wms-filter-tooltip {
                    position: relative;
                    .wms-filter-tooltip-content {
                        position: absolute;
                        top: 24px;
                        left: -135px;
                        min-width: 300px;
                        z-index: 12;
                        background: #ecf5fd !important;
                        font-size: 14px;
                        color: #7d7d7d !important;
                        border: 1px solid #00000014 !important;
                        text-align: center;
                        padding: 5px 0;
                        min-height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:before {
                            content: "\A";
                            border-style: solid;
                            border-width: 11px 11px 0px 20px;
                            border-color: transparent #0079c4 transparent transparent;
                            position: absolute;
                            left: auto;
                            top: 1px;
                            transform: rotate(-135deg);
                            -webkit-transform: rotate(-135deg);
                        }
                    }
                }
            }
            .grid-right-content{
                float: right;
                position: relative;
                display: flex;
            .wms-legend-tooltip{
                .wms-filter-tooltip-content {
                    position: absolute;
                    top: 37px;
                    left: auto;
                    right: 15px;
                    // min-width: 300px;
                    z-index: 12;
                    font-size: 14px;
                    text-align: center;
                    padding: 5px 0;
                    background-color: #FFFFFF;
                    border-radius: 0;
                    border: 0;
                    -webkit-box-shadow: 0px 0px 10px 0 rgba(50, 50, 93, 0.1);
                    -moz-box-shadow: 0px 0px 10px 0 rgba(50, 50, 93, 0.1);
                    -ms-box-shadow: 0px 0px 10px 0 rgba(50, 50, 93, 0.1);
                    -o-box-shadow: 0px 0px 10px 0 rgba(50, 50, 93, 0.1);
                    box-shadow: 0px 0px 10px 0 rgba(50, 50, 93, 0.1);
                    width: 200px;
                    &:before {
                        content: "\A";
                        position: absolute;
                        right: 1px;
                        top: -22px;
                        transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                        width: 0;
                        height: 0;
                        border-left: 16px solid transparent;
                        border-right: 16px solid transparent;
                        border-bottom: 23px solid #fff;
                    }
                    ul{
                        li{
                            padding: 17px 20px;
                            float: left;
                            .wms-color-box1{
                                background-color: rgba(234, 59, 36, 0.2);
                                padding: 3px 11px;
                                margin-right: 13px;
                                border-left: 4px solid rgba(234, 59, 36, 1);
                                border-radius: 2px;
                            }
                            .wms-color-box2{
                                background-color: rgba(244, 137, 49, 0.2);
                                padding: 3px 11px;
                                margin-right: 13px;
                                border-left: 4px solid rgba(244, 137, 49, 1);
                                border-radius: 2px;
                            }
                            .wms-color-box3{
                                background-color:  rgba(9, 90, 168, 0.2);
                                padding: 3px 11px;
                                margin-right: 13px;
                                border-left: 4px solid rgba(9, 90, 168, 1);
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
            .wms-filter-tooltip{
                cursor: pointer;
                padding-left: 16px;
            }
            }
        }
        .wms-findorder-grid-details{
            background: #e2effc;
            display: flex;
            border: 1px solid #00000014;
            border-top: none;
            .wms-details-data-section{
                width: 14%;
                border-right: 1px solid #00000014;
                text-align: center;
                .details-count{
                    font-size: 20px;
                    padding: 0;
                    margin: 5px 0 0 0;
                    font-weight: bold;
                    color: black;
                }
                .details-name{
                    margin: 0px 0 10px 0;
                }
            }
            .wms-details-radio-section {
                flex: 1;
                padding: 0;
                display: flex;
                align-items: center;
                margin-left: 15px;
                .wms-radiobtn-component {
                    margin: 0 10px;
                    padding: 5px;
                    .wms-radiobtn  {
                        margin: 0;
                        label {
                            display: flex;
                            align-items: center;
                            .radio {
                                margin: 0 5px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .wms-grid-area-wrapper {
        position: relative;
        // width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        min-height: 400px;
        max-height: 800px;
        border: 1px solid #00000014;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .wms_area_border {
            overflow: hidden;
            width: 100%;
            height: 100%;
            .gridContainer {
                overflow: hidden;
                height: 100%;
                .k-grid {
                    border: 0px solid #ebebeb !important;
                    border-top: none !important;
                    .k-loading-mask {
                        background-color: rgba(255, 255, 255, 0.3) !important;
                    }

                    // This css for locable grid remaining fields 100% start
                    .k-grid-header{
                        .k-header {
                            .k-header-column-menu {
                                &.k-state-active {
                                    background-color: #ffffff !important;;
                                    border-color: #e2effc !important;
                                }
                            }
                        }
                        .k-grid-header-wrap {
                            border-color:  rgba(0, 0, 0, 0.08);
                            border-width: 0 0 0 0;
                            table {
                                width: 100% !important;
                                .k-header-column-menu {
                                    z-index: 1;
                                    bottom: 0;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .k-grid-content-locked{
                        table{
                            tr {
                                td {
                                    padding-left: 13px;
                                    padding-right: 8px;
                                }
                            }
                        }
                    }
                    .k-grid-content, .k-grid-content-locked {
                            table{
                                width: 100% !important;
                                tbody {
                                    tr {
                                        td {
                                            border-bottom: 0;
                                        }
                                        &.wms-no-line-items {
                                            td {
                                                color: rgba(234, 59, 36, 1) !important;
                                            }
                                        }
                                        &.wms-no-line-items-left-border {
                                            td:first-child {
                                                padding: 6px 8px;
                                                border-left: 5px solid rgba(234, 59, 36, 1);
                                            }
                                        }
                                        &.wms-partially-allocated {
                                            td {
                                                color: rgba(9, 90, 168, 1) !important;
                                            }
                                        }
                                        &.wms-partially-allocated-left-border {
                                            td:first-child{
                                                padding: 6px 8px;
                                                border-left: 5px solid rgba(9, 90, 168, 1);
                                            }
                                        }
                                        &.wms-un-allocated {
                                            td {
                                                color: rgba(244, 137, 49, 1) !important;
                                            }
                                        }
                                        &.wms-un-allocated-left-border {
                                            td:first-child {
                                                padding: 6px 8px;
                                                border-left: 5px solid rgba(244, 137, 49, 1);
                                            }
                                        }

                                        &.k-state-selected {
                                            td {
                                                color: #005CA8 !important;
                                                font: Bold 13px Open Sans;
                                                background-color: #E2EFFC !important;
                                            }
                                        }
                                        &.k-alt {
                                            &.k-state-selected {
                                                td {
                                                    background-color: #D0E5FA !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            background-color: #ffffff;
                    }

                    // This css for locable grid remaining fields 100% end
                    .k-grid-header {
                        .k-header {
                            font-size: 14px;
                            font-weight: 700;
                            padding: 6px 12px;
                            .k-link {
                                .k-icon {
                                    vertical-align: middle !important;
                                }
                            }
                            .k-i-sort-desc-sm,
                            .k-i-sort-asc-sm {
                                &::before {
                                    color: $input-color;
                                }
                            }
                        }
                        .k-grid-header-locked {
                            // width: 60px !important;
                            // opacity: 0.7;
                            border-color: #00000014;
                            table{
                                thead{
                                    tr{
                                        th{
                                            .k-header-column-menu {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .k-grid-content {
                        // border: 1px solid #ebebeb !important;
                        border-top: none !important;
                        tr{
                            &.k-state-selected {
                                td {
                                    // color: $input-color !important;
                                    font: Bold 13px Open Sans;
                                    color: #005CA8 !important;
                                    background-color: #E2EFFC !important;
                                }
                            }
                            &.k-alt {
                                &.k-state-selected {
                                    td{
                                        background-color: #D0E5FA !important;
                                    }
                                }
                            }
                        }
                    }
                    tr {
                        td {
                            padding: 6px 12px;
                            color: $input-color;
                            font-size: 13px;
                            white-space: nowrap;
                            // remove box shadow on navigate or selection
                            &.k-state-focused{
                                box-shadow: none;
                            }
                        }
                        th{
                            // remove box shadow on navigate or selection
                            &.k-state-focused{
                                box-shadow: none;
                            }
                        }
                        &.k-alt {
                            background-color: $inputs_bg_color;
                            &.wms-over-allocated {
                                background-color: #EDDDD4 !important;
                            }
                        }
                        &.wms-over-allocated {
                            background-color: #F2E1D8 !important;
                            td {
                                color: $input-color !important;
                            }
                            &.k-state-selected {
                                background-color: $selected-rowbg !important;
                                td {
                                    color: $input-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .k-grid-content-locked{
            // width: 60px !important;
            background: white !important;
            border: none;
            table{
                tr{
                    &.k-state-selected {
                        td {
                            // color: $input-color !important;
                            color: #005CA8 !important;
                            font: Bold 13px Open Sans;
                            background-color: #E2EFFC !important;
                        }
                    }
                    &.k-alt {
                        &.k-state-selected {
                            td{
                                background-color: #D0E5FA !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .wms-button-dropdown-component{
        .wms_dropdown_button{
            .k-animation-container{
                .mydisabled {
                    cursor: not-allowed !important;
                    background: #f9f9f9 !important;
                    color: #bdbcbc !important;
                    opacity: 1 !important;
                    pointer-events: all !important;
                    &.k-state-focused{
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                    .k-link {
                        cursor: not-allowed !important;
                    }
                }
                .option-saperator {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                }
            }
        }
    }
}
.k-animation-container {
    .k-column-menu {
        width: 95px;
        height: 29px;
        padding: 5px;
        .k-state-focused {
            box-shadow: unset !important;
        }
    }
}