@import "../../assets/scss/common";

.wms-button-component {
.button_holder {
    position: relative;
    button {
      font-size: 14px;
      border-radius: 30px;
      color: white;
      padding: 8px 20px 8px 15px;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      box-shadow: none;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      height: 33px;
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      i {
        // padding-right: 5px;
        width: 23px;
      }
      .btnloader {
        // margin-right: 5px;
        width: 23px;
      }

      &.yellow {
        background: $yellowbtn;

        &:hover,
        &:active,
        &:focus {
          background: $yellowbtn-hover;
        }
      }

      &.green {
        background: $primary;

        &:hover,
        &:active,
        &:focus {
          background: $primary-hover;
        }
      }

      &.blue {
        background: $secondary;

        &:hover,
        &:active,
        &:focus {
          background: $secondary;
        }
      }

      &.red {
        background: $redbtn;

        &:hover,
        &:active,
        &:focus {
          background: $redbtn-hover;
        }
      }

      &.orange {
        background: $orange;

        &:hover,
        &:active,
        &:focus {
          background: $orange-hover;
        }
      }
      // button[disabled] {
      //   opacity: 0.6;
      // }
    }
    &.disabled_button{
        .wms_toolbar_button {
            pointer-events: none;
            opacity: 0.2;
        }
    }
    .div_disable {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: not-allowed;
      height: 40px;
      display: inline-block;
    }
    .wms-button-tooltip {
        position: absolute;
        top: 40px;
        left: 0;
        min-width: 130px;
        z-index: 12;
        background: #ecf5fd !important;
        font-size: 14px;
        color: #7d7d7d !important;
        border: 1px solid #b7d2e5 !important;
        padding: 4px 8px;
        &:before {
            content: "\A";
            position: absolute;
            top: 1px;
            left: 30px;
            border-style: solid;
            border-width: 11px 11px 0px 20px;
            border-color: transparent #0079c4 transparent transparent;
            -webkit-transform: rotate(-135deg);
        }
    }
  }
}
