main {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.dashboard-default-section-wrapper {
  .msg-wrapper {
    padding: 50px;
    margin-left: 50px;
    span {
      color: #999999;
      font-size: 26px;
      right: 0;
      font-weight: 300;
    }
  }
}
