/**==========  Common Variables  ==========**/

$primary: #81c926 !default;
$primary-hover: #94dc3a;
$secondary: #005CA8 !default;
$secondary-hover: #006aac;
$text-color: #333333 !default;
$light-grey: #ecebeb;
$black: #222222;
$red: #ff0000;
$background-light: #f5f5f5;

//---- Input Variables -----
$requireinput-border: #d79093;

//---- Buttons Variables -----
$yellowbtn: #faca00;
$yellowbtn-hover: #fad745;
$redbtn: #ff6262;
$redbtn-hover: #ff7d7d;
$blackbtn: #666666;
$blackbtn-hover: #777777;

// ==============================================
//------ WMS Variables -------
//$icon-path: "../../assets/images/";

/**==========  Common Variables  ==========**/
$primary-font: "Heebo", Arial, sans-serif;
$primary-fontsize: 14px;
$primary-white: #ffffff;
$primary-black: #000000;
$green-menu: #83de52;
$blue-menu: #28b7ed;
$orange-menu: #f6bb42;
$requireinput-border: #d79093;
$primary-button-bg: #0079c4;
$primary-button-pressed-bg: #0079c4;

//---- Buttons Variables -----
$greenbtn: #81c926;
$greenbtn-hover: #94dc3a;
$yellowbtn: #faca00;
$yellowbtn-hover: #fad745;
$redbtn: #ff6262;
$redbtn-hover: #ff7d7d;
$blackbtn: #666666;
$blackbtn-hover: #777777;
$orange: #fe6801;
$orange-hover: #e66309;

/**==========  ThemeColor Variables  ==========**/
$spinner-color: rgba(255, 255, 255, 0.6);
$primary-themecolor: #0079c4;
$body-bg: #ecf5fd;
$window-title: #2c405a;
$window-closeicon: #a8c6df;
$window-titlebg: #ffffff;
$transparent-bg: rgba(255, 255, 255, 0);
$seprator-color: #b7d2e5;
$overAllocated: #f1e0dc;
$resetbtn-bg: #005CA8;
$resettext-bg: #006aac;
$resetbtn-bg-hover: #006aac;

//---- SideNavigation Variables -----
$sidepanel-bg: #2c405a;
$submenu-hoverbg: rgba(255, 255, 255, 0.1);
$submenufont: #ffffff;
$submenufont: #ffffff;

//---- HeaderTab Variables -----
$active-windowbg: rgba(255, 255, 255, 1);
$deactive-windowbg: rgba(255, 255, 255, 0.25);
$active-windowborder: #0079c4;
$header-tabtitle: #666666;
$header-tabtext: #4a4a4a;
$header-tablabel: #799ab6;

//---- FontColor Variables -----
$primary-textcolor: #3f536e;
$form-title: #5a7e9d;
$filter-note-title: #e67b24;

//---- Form Variables -----
$form-boxbg: #fafbfc;
$label-color: #7d7d7d;
$input-border: #d8e5ee;
$input-bg: #ffffff;
$datepicker-icon: #2c405a;
$datepicker-border: #afbcc7;
$checked-bg: rgba(255, 255, 255, 1);
$checked-wavebg: #0079c4;

//---- TabListing Variables -----
$accordian-title: #0079c4;
$tablabel-border: #c5d9e8;
$tablabel-bg: rgba(0, 147, 238, 0.05);
$tablabel-hoverbg: rgba(0, 147, 238, 0.25);
$activetab-border: rgba(0, 0, 0, 0.08);
$collapsecontent-bg: #ffffff;

//---- Table Variables -----
$tabel-headbg: #cee1f2;
$tableheader-rowbg: #cee1f2;
$table-rowbg: #ffffff;
$table-alterrowbg: #fafbfc;
$selected-rowbg: #8eb8dd;
$gridheader-textcolor: #527999;
$tblfont-color: #3f536e;
$tblcolumn-rightborder: #b7d2e5;
$tblbottom-border: rgba(255, 255, 255, 0.15);

// Search New Design

//---- Collapse Variables -----

$blue_color: #008edd;
$collapse-header-color: #005CA8;
$inputs_bg_color: #f5f5f5;
$title_color: #444444;


// new design color
$input-color: #222222;
$border-color:#bbbbbb;
