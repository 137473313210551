@import "../assets/scss/common";
@import "../assets/scss/variables";
@import "../assets/scss/mixing";

.email-section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 26px;
  .tab-listing {
    display: flex;
    height: 34px;
  }
  .tab-content {
    margin-top: 30px;
    display: flex;
    // flex: 1;
    .email-tab-content-wrapper {
      display: flex;
      flex: 1;
      .wms-collapsible-div {
        height: 100%;
        width: 100%;
        .wms-collapsible-content {
          height: 100%;
          .variable-branch-wrapper {
            display: flex;
            margin: 0px 20px 0px 20px;
            position: absolute;
            .variable-branch-name {
              margin-right: 15px;
              input {
                height: 32px;
              }
            }
          }
        }
      }
    }
  }
}
