@import "../assets/scss/common";
@import "../assets/scss/variables";
@import "../assets/scss/mixing";


.staging-section-wrapper {  
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 26px;
  .tab-listing {
    display: flex;
    height: 34px;
  }    
  .tab-content {
    margin-top: 30px;
    display: flex;
    // flex: 1;
    .staging-tab-content-wrapper {
        display: flex;
        flex: 1;
        .wms-collapsible-div  {
          height: 100%;
          width: 100%;
          .wms-collapsible-content {
            height: 100%;
            .variable-branch-wrapper {
              display: flex;
              margin: 0px 20px 0px 20px;
              position: absolute;
              .variable-branch-name {
                margin-right: 15px;
                input {
                  height: 32px;
                }
              }
            }
            .production-password {
                position: absolute;
                width: 20%;
                margin: 0 20px;
            }
            .code-same-notice {
                position: absolute;
                background-color: #ff0000;
                padding: 10px 35px;
                font-weight: 900;
                letter-spacing: 0.5px;
                margin: 0 20px;
                color: #ffffff;
            }
          }
        }
      .wms-group-grid-holder {
        .refresh-btn-wrapper {
          padding-left: 10px;
          button {
            padding: 8px 10px 8px 10px;
          }
        }
      }
    }
  }
  .infrastructure-wrapper {

    .wms-collapsible-div  {
      height: 100%;
      width: 100%;
      .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        .wms-button-component {
          .button_holder {
            padding-left: 15px;
          }
        }
      }
    }
  }
  h3 {
    display: flex;
    justify-content: center;
  }
}
