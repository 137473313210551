@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";


.wms-input-component {
  margin-bottom: 8px;

  label {
    font-weight: 400;
    font-size: 10px;
    color: #222222;
    margin-bottom: 4px;
  }

  input {
    font-weight: 600;
    font-size: 14px;
    color: #222222;
    width: calc(100% - 22px);
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    outline: none;
    box-shadow: none;
    background-color: white;
  }
}
