@import "../assets/scss/common";
@import "../assets/scss/variables";
@import "../assets/scss/mixing";


.git-learning-section-wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  .wms-collapsible-div  {
      .wms-collapsible-content {
        padding: 30px;
        p {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 0px;
          color: #7f7f7f;
          font-weight: 400;
          margin-left: 0px;
          margin-right: 0;
        }
        p:first-child {
          margin-top: 0;
        }
        // p:last-child {
        //   margin-bottom: 0;
        // }
        a {
          span {
            cursor: pointer;
          }
        }
      }
  }
}
