@import "../../assets/scss/variables";
@import "../../assets/scss/mixing";

.wms-collapsible-div {
    margin-bottom: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #eeeeee;
    border-top: none;
    display: flex;
    flex-direction: column;
    &.wms-collapse-disabled {
        opacity: 0.5;
        .wms_collapsible_header {
            pointer-events: none;
        }
        .wms-collapsible-content {
            pointer-events: none;
        }
    }
    .wms_collapsible_header {
        position: relative;
        min-height: 37px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid #f5f5f5;
        cursor: pointer;
        &::after {
            content: "";
            height: 5px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 4px 4px 0 0;
            background-color: $collapse-header-color;
        }
        .wms-title {
            cursor: default;
            display: block;
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
            text-transform: uppercase;
            color: $input-color;
            flex: 1;
        }

        .wms-sort {
            position: absolute;
            top: 2px;
            left: 0px;
            color: #dadada;
            padding: 9px 7px;
            // z-index: 1;
            cursor: move;
        }

        label {
            cursor: pointer;
            padding: 10px 0px 6px 12px;
            i {
                font-size: 14px;
                color: $collapse-header-color;
                margin-right: 12px;
            }
            &.label-text-padding {
                padding: 0 15px 0;
            }
        }
        .title-info {
            display: flex;
            .fa-info-circle {
                margin: 11px 5px 5px 5px;
                color: #81c926;
            }
        }
    }
    .wms-collapsible-content {
        padding: 15px;
        background: #ffffff;
        position: relative;
        border-radius: 0 0 4px 4px;
    }
}
