@import "./assets/scss/common";
@import "./node_modules/@progress/kendo-theme-default/scss/all";
html,
body,
#root {
  // height: 100%;
  // width: 100%;
}

body {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
