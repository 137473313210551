.wms-bg-cover {
  background: url("../assets/images/image-2.png") no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .wms-inner-block {
    position: relative;
    .wmsi-error-msg-box {
      padding: 10px;
      background: #fff;
      width: 768px;
      margin: 0 auto;
      border-radius: 4px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
      margin-bottom: 30px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: -140px;
      &.show-error-msg {
        opacity: 1;
      }
      .wmsi-error-bg-block {
        background: rgba(234, 59, 36, 0.2);
        border: 1px solid #ea3b24;
        padding: 20px 22px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        h4 {
          font-weight: 600;
          color: #ea3b24;
          font-size: 16px;
          margin: 0;
        }
        p {
          font-weight: 400;
          color: #ea3b24;
          font-size: 16px;
          margin: 0px 0px 0px 44px;
        }
        .wmsi-error-icon {
          display: inline-flex;
          align-items: center;
          i {
            font-size: 40px;
            color: #ea3b24;
          }
        }
      }
    }
    .wmsi-modal-block {
      .wms-middle-box {
        background-color: #fff;
        width: 788px;
        height: 385px;
        border-radius: 4px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
        margin: 0 auto;
        .wms-middle-block {
          padding: 8px;
          display: flex;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
          .wms-half {
            background: #008edd;
            width: 53%;
            display: flex;
            flex-direction: column;
            .wms-blue-block {
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              img {
                width: 100%;
              }
            }
          }
          .wms-white-block {
            display: flex;
            flex-direction: column;
            padding: 15px 20px;
            flex: 1;
            h3 {
              font-weight: 600;
              font-size: 16px;
              color: #222222;
              // margin: 0 0 45px 0;
              margin: 0 auto 45px;
            }
            .wms-submitbtn {
              text-align: center;              
              // margin-top: 50px;
              // margin-top: 15px;
              position: relative;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              top: 25%;
              &.wms-disabled {
                opacity: 0.4;
              }
              button {
                display: inline-flex;
                padding: 8px 38px 8px 38px;
              }
              .wms-separator {
                display:flex;
                justify-content:center;
                align-items: center;
                color:#bbbbbb;
                margin: 10px 0;
                &::before {
                  margin-right: 10px;
                }
                &::after {
                  margin-left: 10px;
                }
                &::before,
                &::after {
                  content: "";
                  display: block;
                  background: #bbbbbb;
                  width: 100%;
                  height:1px;
                }
              }
            }
          }
        }
        .wms-copyright {
          font-weight: 400;
          font-size: 11px;
          color: #fff;
          display: inline-block;
        }
      }
    }
  }
}
