@import "../../assets/scss/common";

.wms-navigation-bar {
  width: 200px;
  background: #333333;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .wms-logo-container {
    height: 100px;
    background: #008edd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wms-menu-list-container {
    .wms-menu-list {
      background: none;
      border: none;
      padding-top: 20px;

      .wmsi-sidebar-menu-list {
        padding: 10px 5px;
        background: none;
        border: none;
        &:focus {
          box-shadow: none !important;
        }
        &.k-state-hover {
          background: none;
          span {
            opacity: 1;
          }
        }
        &.active-tab {
          span {
            opacity: 1;
          }
        }
        &.k-state-selected {
          background: none;
        }
        &.k-state-default {
          background: none;
        }
        &.k-state-border-right {
          background: none;
        }
        &.k-state-border-down {
          border: none;
        }
        &.k-state-focused {
          border: none;
          box-shadow: none;
        }
        span {
          transition: all 0.3s ease-in-out;
          opacity: 0.5;
          i {
            margin-right: 8px;
            color: #fff;
            font-size: 14px;
          }
          .wmsi-menu-list {
            color: #fff;
            font-weight: 400;
            font-size: 14px;
          }
          .k-icon:before {
            color: #fff;
          }
        }
        .k-animation-container {
          margin: auto;
          top: -120px !important;
          height: 100vh !important;
          overflow: hidden !important;
          width: 210px !important;
          ul {
            background: #545454;
            height: 100vh !important;
            max-height: 100% !important;
            overflow-y: hidden !important;
            .wmsi-functions-block-title {
              background: #333333;
              padding: 20px 20px 21px;
              margin-bottom: 20px;
              h3 {
                font-size: 20px;
                font-weight: 600;
                color: #fff;
                margin: 0;
              }
              p {
                font-size: 20px;
                font-weight: 400;
                color: #fff;
                margin: 0;
              }
            }
            li {
              &.wmsi-sidebar-dropdown-list {
                padding: 10px;
                background: none;
                &:focus {
                  box-shadow: none !important;
                }
                &.k-state-hover {
                  background: none !important;
                  border: none !important;
                  span {
                    opacity: 1;
                  }
                }
                &.k-state-focused {
                  border: none;
                  box-shadow: none;
                  span {
                    opacity: 1;
                  }
                }
                &.active-tab {
                  span {
                    opacity: 1;
                  }
                }
                .wmsi-sidemenu-details {
                  border: 1px solid #fff;
                  border-radius: 30px;
                  padding: 2px 6px;
                  font-size: 10px;
                  font-weight: 500;
                  margin-left: 8px;
                  display: flex;
                }
                &.active-tab {
                  span {
                    opacity: 1;
                  }
                }
              }
              span {
                color: #fff !important;
                font-weight: 400;
                font-size: 14px;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
